import 'tw-elements';
import $ from 'jquery';
import modal from './components/modal';
import cookieWarning from './cookie-warning';

//review rating
function setRating(rating) {
    $('input#rating').val(rating);
    $('div#new-rating').text(rating);
    $('.bt-set-rating').each(function (i, el) {
        if ($(el).data('rating') <= rating) {
            $(el).addClass('filled');
            $(el).removeClass('not-filled');
        } else {
            $(el).addClass('not-filled');
            $(el).removeClass('filled');
        }
    });
}

function reviewText(el) {
    const text = $(el).val();
    if (!text) {
        return;
    }
    const words = text.trim().split(" ");
    $('#review-text-words').text(words.length);
    $('#review-text-letters').text(text.length);
    $('#review-text-big').hide();
    $('#review-text-small').hide();
    if (words.length < 20 || text.length < 100) {
        $('#review-text-small').show();
    }
    if (words.length > 140) {
        $('#review-text-big').show();
    }
}
$(() => {
    //set rating
    $('#set-rating').on('click', '.bt-set-rating', function (e) {
        e.preventDefault();
        let rating = $(this).data('rating');
        setRating(rating);
    });
    let rating = $('input#rating').val();
    setRating(rating);

    //review text
    reviewText('#review-text');
    $('#review-text').on('keyup', function (e) {
        reviewText(this);
    })

    //account review item
    $('.bt-remove-review').on('click', function (e) {
        e.preventDefault();

        const link = $(this).data('link');
        console.log(link);
        $('#bt-remove-review').attr('href', link);

        modal.show('#modal-remove-review');
    });

    //review like
    $('.bt-review-like').on('click', function (e) {
        e.preventDefault();
        const url = $(this).data('url');
        console.log(url);
        $.getJSON(url).done((data) => {
            if (data.valid) {
                $(this).closest('div').children().removeClass('opacity-100');
                $(this).addClass('opacity-100').removeClass('opacity-50');
                if (data.data.type == 1 || data.data.type == true) {
                    $(this).closest('div').children('.unliked').addClass('opacity-50');
                } else {
                    $(this).closest('div').children('.liked').addClass('opacity-50');
                }
            } else if (data.error == 1) {
                alert('Você não está logado e será direcionado.');
                window.location.href = data.url;
            }
        }).fail(function (jq, text, error) {
            alert('Ops! Ocorreu um erro. Tente novamente mais tarde...');
        });
    });
    $('.review-item').each(function (i) {
        const url = $(this).data('url');
        if (url) {
            $.getJSON(url).done((data) => {
                if (data.valid) {
                    const revItem = $('.review-item[data-id="' + data.data.review + '"]');
                    if (data.data.type) {
                        $(revItem).find('.liked').removeClass('opacity-50').addClass('opacity-100');
                    } else {
                        $(revItem).find('.unliked').removeClass('opacity-50').addClass('opacity-100');
                    }
                }
            }).fail(function (jq, text, error) {
                console.log('Ops! Ocorreu um erro. Tente novamente mais tarde...');
            });
        }
    });

    //content watched/readed
    //review like
    $('.bt-watched').on('click', function (e) {
        e.preventDefault();
        const url = $(this).data('url');
        console.log(url);
        $.getJSON(url).done((data) => {
            if (data.valid) {
                $(this).addClass('bg-gr-warning').removeClass('bg-gr-success');
            } else if (data.error == 1) {
                alert('Você não está logado e será direcionado.');
                window.location.href = data.url;
            }
        }).fail(function (jq, text, error) {
            alert('Ops! Ocorreu um erro. Tente novamente mais tarde...');
        });
    });
    $('.bt-watched').each(function (i) {
        const url = $(this).data('url-status');
        if (url) {
            $.getJSON(url).done((data) => {
                if (data.valid) {
                    const revItem = $('.bt-watched[data-content="' + data.data.content + '"]');
                    $(revItem).addClass('bg-gr-warning').removeClass('bg-gr-success');
                }
            }).fail(function (jq, text, error) {
                console.log('Ops! Ocorreu um erro. Tente novamente mais tarde...');
            });
        }
    });

    // Register Google
    $('#bt-google-register').on('click', function (e) {
        e.preventDefault();
        modal.show('#modal-google-register');
    });

    $('#loading').delay(200).fadeOut();

    // Exite a tela de cookie
    cookieWarning();
});