import $ from 'jquery';

export default {
    show(id) {
        $(id).removeClass('hidden').addClass('flex').addClass('modal');
        $(id).on('click', '.modal-close', (e) => {
            e.preventDefault();
            this.hide();
        });
        $(document).on('keyup', (evt) => {
            if (evt.keyCode == 27) {
                this.hide();
            }
        });
    },
    hide() {
        $('.modal').addClass('hidden').removeClass('flex');
    }
}