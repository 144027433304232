export default function () {
    var purecookieTitle = "Aviso de Cookies",
        purecookieDesc = "Este site usa cookies. Ao continuar navegando neste site, você concorda com nossos termos em ",
        purecookieLink = '<a href="https://gospelreviews.com.br/politicas-de-privacidade" target="_blank">Termos de Uso e Políticas de Privacidade</a>',
        purecookieButton = "Aceitar e continuar";
    function setCookie(e, o, i) {
        var t = "";
        if (i) {
            var n = new Date;
            n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3),
                t = "; expires=" + n.toUTCString()
        }
        document.cookie = e + "=" + (o || "") + t + "; path=/"
    }
    function getCookie(e) {
        for (var o = e + "=", i = document.cookie.split(";"), t = 0; t < i.length; t++) {
            for (var n = i[t]; " " == n.charAt(0);)
                n = n.substring(1, n.length);
            if (0 == n.indexOf(o))
                return n.substring(o.length, n.length)
        }
        return null
    }
    function eraseCookie(e) {
        document.cookie = e + "=; Max-Age=-99999999;"
    }
    function cookieConsent() {
        if (getCookie("purecookieDismiss") == null) {
            $(document.body).append('<div class="cookieConsentContainer" id="cookieConsentContainer"><div class="cookieTitle"><a>' + purecookieTitle + '</a></div><div class="cookieDesc"><p>' + purecookieDesc + " " + purecookieLink + '</p></div><div class="cookieButton"><a onClick="purecookieDismiss();">' + purecookieButton + "</a></div></div>");
            $("#cookieConsentContainer").fadeIn();
        }
    }
    window.purecookieDismiss = function () {
        setCookie("purecookieDismiss", "1", 7);
        $("#cookieConsentContainer").fadeOut();
    }
    $(() => {
        cookieConsent();
    });
}